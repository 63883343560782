import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "type-refinement"
    }}>{`Type Refinement`}</h1>
    <hr></hr>
    <p>{`Type refinement is an ability to prove that your variable has specific type. It helps you to build more type safe program when you work with user input or server response and don't miss static type analysis.`}</p>
    <p>{`Lets explore the next example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function calculateSum(firstUserInput: unknown, secondUserInput: unknown) {
  if (typeof firstUserInput !== "number") {
    throw new TypeError(
      "first provided value has a wrong type. Shoulde be a number"
    );
  }
  if (typeof secondUserInput !== "number") {
    throw new TypeError(
      "second provided value has a wrong type. Shoulde be a number"
    );
  }
  return firstUserInput + secondUserInput;
}
`}</code></pre>
    <p>{`If you open this example in `}<a parentName="p" {...{
        "href": "/try#GYVwdgxgLglg9mABBAhgGwiNKoFMDKIAtgBTAwBOAzlAKpW4UCSYADiFAFyLgDWYcAO5gANIgYQEAE3qMW7LjzD8hYAJSIA3gChEiGMEQkoAT1a44h8tToNmbDogCEAXheIARGGIAjRh40dPT0oAAsKIUQwXEFEABUzXABRCgiKEg9rGkRWCIA3GClcKUQ89BBcRFCUKkQUREEIsABzRFNzADpEfFC4LCLEPzqo3381AG5dRABfKYMjdotDCWlZewVnN09vIj8KAK0pkPDI6NiE8xS0jJWwEty4AqKSsrQKqprhxoRWxa6evpoAZDeo7PYBSZ6WZ6Ci4KAgChILK2OQOKCIADU4lwkjua3kHEm0yAA"
      }}>{`Playground`}</a>{` then you will see that Hegel doesn't show any type error at the 8 line. But why?`}</p>
    <p>{`It's because we proved by conditions that "firstUserInput" and "secondUserInput" will always be a numbers at the 8 line.`}</p>
    <p>{`So, type refinement it's a specific condition inside `}<inlineCode parentName="p">{`if`}</inlineCode>{`, `}<inlineCode parentName="p">{`while`}</inlineCode>{`, `}<inlineCode parentName="p">{`do..while`}</inlineCode>{`, `}<inlineCode parentName="p">{`for`}</inlineCode>{`, ternary and logical operators, which precise the type of variable.`}</p>
    <p>{`Hegel has several conditions which could be used as refinement operator.`}</p>
    <p>{`Table of contents:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#equality"
        }}>{`Equality`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#switch"
        }}>{`switch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#identifier"
        }}>{`Identifier`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#typeof"
        }}>{`typeof`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instanceof"
        }}>{`instanceof`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#in"
        }}>{`in`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#not"
        }}>{`Not`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#combinations"
        }}>{`Combinations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#return-break-continue-throw"
        }}>{`return, break, continue, throw`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#needlessrefinement"
        }}>{`Needless refinement`}</a></li>
    </ul>
    <h2 {...{
      "id": "equality"
    }}>{`Equality`}</h2>
    <p>{`The most simple refinement operator is equality. You only may prove equality of variable and some literal inside the block like this`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function get2(arg: unknown): 2 {
  if (arg === 2) {
    // Inside if block type of "arg" variable is "2"
    return arg;
  }
  throw new TypeError("Arg is not 2");
}
`}</code></pre>
    <h2 {...{
      "id": "switch"
    }}>{`switch`}</h2>
    <p>{`The same logic has switch expression. In each case you prove that variable equals to a value. But with an exception - if you drop "break", "return" and "throw" statement from case then the next case will include previous prove.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type User = { name: string; age: number; id: number };

type Action =
  | { type: "DELETE_USER"; payload: { userId: number } }
  | { type: "REMOVE_USER"; payload: { userId: number } }
  | { type: "CREATE_USER"; payload: { user: User } };

function reducer(action: Action) {
  switch (action.type) {
    case "REMOVE_USER":
    case "DELETE_USER":
      // In this case action type is "{ payload: { userId: number }, type: 'DELETE_USER' } | { payload: { userId: number }, type: 'REMOVE_USER' }"
      return "User deleted";
    case "CREATE_USER":
      // In this case action type is "{ payload: { user: { age: number, id: number, name: string } }, type: 'CREATE_USER' }"
      return action.payload.user;
    default:
      // In this case action type is "never"
      panic(action);
  }
}

function panic(arg: ?never) {
  throw new Error();
}
`}</code></pre>
    <h2 {...{
      "id": "identifier"
    }}>{`Identifier`}</h2>
    <p>{`The second simple refinement operator is identifier. The main restriction of this refinement that it can be used only inside logical expressions`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const maybeTwo: ?number = 2;

// We have proved that "maybeTwo" at the right part of "logical and" is not "falsy", so we can use "maybeTwo" with "+" operator
// Type of "sub" variable is "0 | number | undefined"
const sum = maybeTwo && maybeTwo + 4;

// We have proved that "maybeTwo" at the right part of "logical or" is  "falsy", so we can return something to remove this union case.
// Type of "defaultTwo" variable is "2 | number"
const defaultTwo = maybeTwo || 2;
`}</code></pre>
    <h2 {...{
      "id": "typeof"
    }}>{`typeof`}</h2>
    <p>{`Typeof refinement based on comparison of return value of `}<inlineCode parentName="p">{`typeof`}</inlineCode>{` operator and string literal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const maybeTwo: number | string = 2;

// We have proved that in positive case of condition inside ternary operator "maybeTwo" variable will have a type "string" and in negative - type "number"
// Type of "two" variable is "number"
const two = typeof maybeTwo === "string" ? Number(maybeTwo) : maybeTwo;
`}</code></pre>
    <h2 {...{
      "id": "instanceof"
    }}>{`instanceof`}</h2>
    <p>{`Instanceof refinement prove that variable or property inside variable is instance of provided constructor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class User {}
class Admin extends User {
  sayHiToAdmin() {}
}

const user = new User();

if (user instanceof Admin) {
  user.sayHiToAdmin();
}
`}</code></pre>
    <h2 {...{
      "id": "in"
    }}>{`in`}</h2>
    <p>{`In refinement prove that variable or property inside variable has specified property.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class User {}
class Admin {
  sayHiToAdmin() {}
}

const user: User | Admin = new User();

if ("sayHiToAdmin" in user) {
  user.sayHiToAdmin();
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const unknownObj: {...} = {};

if ("value" in unknownObj) {

  // Type of "unknownValue" variable is "unknown"
  const unknownValue = unknownObj.value;
}
`}</code></pre>
    <h2 {...{
      "id": "not"
    }}>{`Not`}</h2>
    <p>{`If you use any refinement condition with logical "not" operator or oposite operators like not-equal, strict not-not equal you will prove negative case of your refinement`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Empire {
  aveEmperor() {}
}
class Republic {
  spqr() {}
}

const rome: Empire | Republic = new Empire();

if (!(rome instanceof Republic)) {
  // rome in this case is Empire
  rome.aveEmperor();
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Answer = "To Be" | "Not to Be";

function hamlet(answer: Answer) {
  if (answer !== "To Be") {
    // Type of variable is "'Not to Be'" inside this scope
    return answer;
  }
  throw new Error();
}
`}</code></pre>
    <h2 {...{
      "id": "combinations"
    }}>{`Combinations`}</h2>
    <p>{`Also, you may combine existed refinements via "logical and" or "logical or" operators.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const stranger = JSON.parse("{}");

if (
  typeof stranger === "object" &&
  stranger != null &&
  "secretPhrase" in stranger &&
  stranger.secretPhrase === "valar morghulis"
) {
  // Type of "stranger" variable in this scope is "{ secretPhrase: "valar morghulis", ... }"
  const someoneWithoutName = stranger;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`function detectSong(songPhrase: string) {
  if (
    songPhrase === "It's like I'm paranoid" ||
    songPhrase === "looking over my back"
  ) {
    // Type of "songPhrase" in this scope is "'It's like I'm paranoid' | 'looking over my back'
    const familiarPhrase = songPhrase;
    return "Linkin Park - Papercut";
  }
}
`}</code></pre>
    <h2 {...{
      "id": "return-break-continue-throw"
    }}>{`return, break, continue, throw`}</h2>
    <p>{`If you use next statements (return, throw, break, continue) inside refinement scope, you prove that outside this block your variable will have oposite type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Empire {
  aveEmperor() {}
}
class Republic {
  spqr() {}
}

const rome: Empire | Republic = new Empire();

if (rome instanceof Empire) {
  throw new TypeError("Empire was fallen!");
}

// rome in this case is Republic
rome.spqr();
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Empire {
  aveEmperor() {}
}

const empires: Array<Empire> = [];

empires[1] = new Empire();

for (const empire of empires) {
  if (empire === undefined) {
    continue;
  }
  empire.aveEmperor();
}
`}</code></pre>
    <h2 {...{
      "id": "needless-refinement"
    }}>{`Needless refinement`}</h2>
    <p>{`Sometimes (especially after refactoring) you may do refinement which does not do something useful. As example is provement that type of variable is `}<inlineCode parentName="p">{`number`}</inlineCode>{` while variable type is always `}<inlineCode parentName="p">{`number`}</inlineCode>{`. In this case Hegel try to notify you about it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const calculatedSum = 42 + 14;

// Error: Variable is always "number"
if (typeof calculatedSum === "number") {
}
`}</code></pre>
    <p>{`Another reallife example that after refactoring you dropped a variant of union in switch, but your code still try to handle this case:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type User = { name: string; age: number; id: number };

type Action =
  | { type: "DELETE_USER"; payload: { userId: number } }
  // Deleted after refactoring case
  //  | { type: "REMOVE_USER", payload: { userId: number } }
  | { type: "CREATE_USER"; payload: { user: User } };

function reducer(action: Action) {
  switch (action.type) {
    // Error: Property can't be "'REMOVE_USER'"
    case "REMOVE_USER":
    case "DELETE_USER":
      // In this case action type is "{ payload: { userId: number }, type: 'DELETE_USER' } | { payload: { userId: number }, type: 'REMOVE_USER' }"
      return "User deleted";
    case "CREATE_USER":
      // In this case action type is "{ payload: { user: { age: number, id: number, name: string } }, type: 'CREATE_USER' }"
      return action.payload.user;
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      